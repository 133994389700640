import React from 'react';

import section3Styles from './section3.module.scss';

function renderSection3(props) {
  return (
    <section className={section3Styles.section3}>
      <div className={section3Styles.flex_col}>
        <img
          className={section3Styles.image}
          src={'/assets/a0a9a870542e3a99976e6140e4d1ed14.png'}
          alt="alt text"
          data-aos="fade-left"
        />
        <img
          className={section3Styles.image}
          src={'/assets/855a5fe9a329acf5a560e4fd7d847461.png'}
          alt="alt text"
          data-aos="fade-right"
        />
      </div>
    </section>
  );
}

export default renderSection3;
