import React from 'react';

import section2Styles from './section2.module.scss';

function renderSection2(props) {
  return (
    <section className={section2Styles.section2} data-aos="fade-right">
      <div className={section2Styles.content_box}>
        <h1 className={section2Styles.hero_title1}>Балаковский Мясокомбинат</h1>
        <h1 className={section2Styles.title1_box}>
          <span className={section2Styles.title1}>
            <span className={section2Styles.title1_span0}>
              Производственная программа и спроектированные производственные, технологические и логистические процессы
              мясоперерабатывающего завода по убою КРС и свиней, производству колбасных, деликатесных изделий из мяса,
              производству рубленых п/ф, производству мясных консервов позволит производить первичную и глубокую
              переработку говядины и свинины суточной мощность 50 тн. и более.
              <br />
              <br />
              Проект потребовал больших капиталовложений и создал более 700 рабочих мест.
              <br />
              <br />
              Специалисты ПБ{' '}
            </span>
            <span className={section2Styles.title1_span1}>Деликатес Систем-Проект</span>
            <span className={section2Styles.title1_span2}>
              , достойно справились с масштабными задачами, позволив заказчику реализовать бизнес-идею на практике.
            </span>
          </span>
        </h1>
      </div>
    </section>
  );
}

export default renderSection2;
