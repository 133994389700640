import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import renderSection2 from './section2';
import renderSection3 from './section3';
import renderSection8 from '../Projection/section8';

import styles from './index.module.scss';

function Modeling(props) {
    document.title = "Моделирование";

    return (
    <main className={cn(styles.main, props.className, 'modeling')} style={{overflow:"hidden"}}>
      <img className={styles.cover} src={'/assets/491c77ddbe6e36136d57fbb11fbcd746.png'} alt="alt text" />

      <div className={styles.flex_col}>
          {renderSection2(props)}
          {renderSection3(props)}
          {renderSection8(props)}
      </div>
    </main>
  );
}

Modeling.propTypes = {
  className: PropTypes.string
};

export default Modeling;
