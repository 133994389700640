import React from 'react';

import section7Styles from './section7.module.scss';

function renderSection7(props) {
  return (
    <section className={section7Styles.section7} data-aos="fade-right" id={'7'}>
      <div className={section7Styles.content_box3}>
        <h1 className={section7Styles.title_box}>
          <span className={section7Styles.title}>
            <span className={section7Styles.title_span0}>ПБ </span>
            <span className={section7Styles.title_span1}>Деликатес Систем-Проект</span>
            <span className={section7Styles.title_span2}>
              {' '}
              – семейная компания, работающая в сфере системного проектирования в сфере мяса и птицепереработки.
              <br />
              Мы не столь долго существуем на рынке проектирования, т.к. большую часть нашей профессиональной карьеры мы
              отдали производству в качестве цеховых, ведущих и главных технологов в т.ч. в компаниях федерального
              уровня.
              <br />
            </span>
            <span className={section7Styles.title_span3}>
              Наше убеждение:
              <br />
            </span>
            <span className={section7Styles.title_span4}>
              Никто лучше специалистов-технологов, много лет отдавших реальному производству, не спроектирует завод!
              <br />
            </span>
            <span className={section7Styles.title_span5}>Наша миссия</span>
            <span className={section7Styles.title_span6}>
              {' '}
              – помочь заказчикам найти правильное решение,  разработать корректный, востребованный в конкретном регионе
              ассортимент,  минимизировать затраты на реализацию проекта и совместными усилиями успешно воплотить вашу
              бизнес-идею.
            </span>
          </span>
        </h1>
        <h1 className={section7Styles.hero_title1}>О КОМПАНИИ</h1>
      </div>
    </section>
  );
}

export default renderSection7;
