import React from 'react';

import section3Styles from './section3.module.scss';

function renderSection3(props) {
  return (
    <section className={section3Styles.section3}>
      <div className={section3Styles.flex_col}>
        <img
          className={section3Styles.image1}
          src={'/assets/f81c7beebd62efca471aa6d8129c628d.png'}
          alt="alt text"
          data-aos="fade-left"
        />
        <img
          className={section3Styles.image}
          src={'/assets/d95adabf247ecb3786dcde4ef4636011.png'}
          alt="alt text"
          data-aos="fade-right"
        />
        <img
          className={section3Styles.image11}
          src={'/assets/cbfa0395167edd1c2d5f79a9ba92a715.png'}
          alt="alt text"
          data-aos="fade-left"
        />
      </div>
    </section>
  );
}

export default renderSection3;
