import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import renderSection2 from './section2';
import renderSection3 from './section3';
import renderSection4 from './section4';
import renderSection8 from '../Projection/section8';

import styles from './index.module.scss';

function PreProjection(props) {
    document.title = "Предпроектная стадия";

    return (
    <main className={cn(styles.main, props.className, 'pre-projection')} style={{overflow:"hidden"}}>
      <img className={styles.cover1} src={'/assets/725a93895a78cd9be8559bb9e715dc90.png'} alt="alt text" />

      <div className={styles.flex_col}>
          {renderSection2(props)}
          {renderSection3(props)}
          {renderSection4(props)}
          {renderSection8(props)}
      </div>
    </main>
  );
}

PreProjection.propTypes = {
  className: PropTypes.string
};

export default PreProjection;
