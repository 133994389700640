import React from 'react';

import section2Styles from './section2.module.scss';

function renderSection2(props) {
  return (
    <section className={section2Styles.section2} data-aos="fade-right">
      <div className={section2Styles.content_box1}>
        <h1 className={section2Styles.title1}>
          - Предварительная оценка размера инвестиций.
          <br />- Услуги профессионального технолога: разработка линеек продукции первичной и глубокой переработки
          различных видов мяса и птицы, мастер-классы по выработке продуктов.
          <br />- Разработка оптимального ассортимента.
          <br />- Работа по договору сопровождения проекта (контроль СМР, получение разрешительной документации,
          технологический запуск площадки, обучение персонала).
          <br />
          -Разработка НТД (ТТК, регламенты, СОПы, ТУ, СТО).
        </h1>
        <h1 className={section2Styles.hero_title1}>Консультирование</h1>
      </div>
    </section>
  );
}

export default renderSection2;
