import React from 'react';

import section3Styles from './section3.module.scss';

function renderSection3(props) {
  return (
    <section className={section3Styles.section3} data-aos="fade-right" id={'3'}>
      <div className={section3Styles.content_box}>
        <h1 className={section3Styles.title_box}>
          <span className={section3Styles.title}>
            <span className={section3Styles.title_span0}>
              Мы занимаемся разработкой проектов пищевой промышленности с акцентом на мясо и птицепереработку.
              <br />
              Технологи-проектировщики с огромным производственным опытом, имеющими навыки работы со всеми видами мяса и
              птицы, что позволяет на{' '}
            </span>
            <span className={section3Styles.title_span1}>совершенно ином</span>
            <span className={section3Styles.title_span2}>
              {' '}
              уровне решать сложные задачи, возникающие в ходе работы над проектом, и воплощать самые смелые идеи
              заказчика.
              <br />
            </span>
            <span className={section3Styles.title_span3}>
              Консалтинговые услуги. Оценка целесообразности реализации проекта. Технологические консультации,  с
              выездом на площадку или удаленно.
            </span>
          </span>
        </h1>
        <h1 className={section3Styles.hero_title1}>ВОЗМОЖНОСТИ</h1>
      </div>
    </section>
  );
}

export default renderSection3;
