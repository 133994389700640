import React from 'react';
// import { createRoot } from 'react-dom/client';
import {hydrate, render} from "react-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'normalize.css';
import './common.css';

const container = document.getElementById('root');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript

if (container.hasChildNodes()) {
    hydrate(<App />, container);
} else {
    render(<App />, container);
}

// root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
