import React from 'react';

import section2Styles from './section2.module.scss';

function renderSection2(props) {
  return (
    <section className={section2Styles.section2} data-aos="fade-right">
      <div className={section2Styles.content_box}>
        <h1 className={section2Styles.title1}>
          Отдельное и важное направление для нашей компании - мясоперерабатывающие цеха малой мощности и фермерские
          хозяйства с собственной глубокой переработкой мяса.
          <br />
          <br />
          Фермеры имеют собственную сырьевую базу с отличными функционально-технологическими свойствами мяса от скота,
          выращенного без использования технологий интенсивного индустриального откорма.
          <br />
          Однако, в подавляющем большинстве фермеры не могут реализовать свои неоспоримые преимущества и увеличить
          маржинальность своего бизнеса за счёт глубокой переработки.
          <br />
          <br />
          Существуют и другие серьезные проблемы, как, например, узкая специализация производства. Как показывает
          практика, выработать колбасы и деликатесы из одной говядины, например, в разы затруднительнее, чем из свинины
          и птицы. Мы предлагаем решения этих проблем, стоящих перед фермерскими хозяйствами.
          <br />
          <br />
          Есть еще одна серьезная проблема - подбор оборудования малой мощности, но относящемуся к категории
          промышленного, малые объемы и минимальные площади цеха.
          <br />
          Мы выполнили несколько подобных проектов с последующим запуском производства и получили опыт проектирования
          мясоперерабатывающих цехов в крестьянских фермерских хозяйствах производственной площадью до 200-250 кв.м.{' '}
          <br />
          При этом номенклатура продукции (группы, виды) практически не уступают индустриальным площадкам.
          <br />
          <br />
          Задача проектирования площадки сверхмалой площади с корректно разведёнными потоками сырья, готовой продукции,
          отходов, персонала - задача нетривиальная, требующая нестандартных решений.
          <br />
          {`При этом планировочные решения, технологическая поточность должна проходить аудит сетей, ориентированных на соответствующую целевую аудиторию (продукция премиум-сегмента без замен мясного сырья, выработанная по принципу "Чистая этикетка" (без добавок с индексом Е).`}
          <br />
          <br />
          Кроме того, получения грантов, получения заемов по программам субсидирования, с каждым годом все более зависят
          от наличия у фермера качественно выполненного технологического проекта, с понятной экономикой и конечным
          результатом.
          <br />
          Мы готовы оказать этой категории заказчиков весь спектр услуг по доступной, вполне приемлемой для фермеров
          цене.
        </h1>
        <h1 className={section2Styles.hero_title1}>Для малого бизнеса и фермерских хозяйств</h1>
      </div>
    </section>
  );
}

export default renderSection2;
