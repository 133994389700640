import React from 'react';

import section3Styles from './section3.module.scss';

function renderSection3(props) {
  return (
    <section className={section3Styles.section3} data-aos="fade-left">
      <h1 className={section3Styles.hero_title}>Мастер-классы по выработке различных продуктов</h1>
    </section>
  );
}

export default renderSection3;
