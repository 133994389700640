import React from 'react';

import section5Styles from './section5.module.scss';

function renderSection5(props) {
  return (
    <section className={section5Styles.section5} data-aos="fade-right" id={'5'}>
      <div className={section5Styles.section51}>
        <div className={section5Styles.flex_col}>
          <div className={section5Styles.box6}>
            <h1 className={section5Styles.hero_title4}>широкий спектр услуг по доступной цене</h1>
            <h1 className={section5Styles.hero_title3}>ДЛЯ МАЛОГО  БИЗНЕСА И ФЕРМЕРСКИХ ХОЗЯЙСТВ</h1>
          </div>

          <a href="/Farming" style={{width: "96.03%"}}>
            <div className={section5Styles.flex_row}>
              <h1 className={section5Styles.hero_title2}>Подробнее</h1>
              <img
                className={section5Styles.image4}
                src={'/assets/aca03911ff7eeb73495e1f49016544ac.svg'}
                alt="alt text"
              />
            </div>
          </a>
        </div>
      </div>
    </section>
  );
}

export default renderSection5;
