import React from 'react';

import section7Styles from './section7.module.scss';

function renderSection7(props) {
  return (
    <section className={section7Styles.section7} data-aos="fade-left">
      <img className={section7Styles.image4} src={'/assets/1967e2824b91dce4eb7a93c709e9a6b4.png'} alt="alt text" />
    </section>
  );
}

export default renderSection7;
