import React from 'react';

import section3Styles from './section3.module.scss';

function renderSection3(props) {
  return (
    <section className={section3Styles.section3}>
      <div className={section3Styles.flex_row}>
        <div className={section3Styles.flex_col}>
          <img
            className={section3Styles.image}
            src={'/assets/9ae0211ad5242d74bb512326e72c093e.png'}
            alt="alt text"
            data-aos="fade-left"
          />
          <h1 className={section3Styles.big_title}>Было</h1>
        </div>

        <div className={section3Styles.flex_col1}>
          <img
            className={section3Styles.image1}
            src={'/assets/52fc15e28c4d1a35e5e73bee6e90d65e.png'}
            alt="alt text"
            data-aos="fade-right"
          />
          <h1 className={section3Styles.big_title}>Стало</h1>
        </div>
      </div>
    </section>
  );
}

export default renderSection3;
