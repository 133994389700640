import React from 'react';

import section2Styles from './section2.module.scss';

function renderSection2(props) {
  return (
    <section className={section2Styles.section2} data-aos="fade-right">
      <div className={section2Styles.content_box}>
        <h1 className={section2Styles.title_box}>
          <span className={section2Styles.title}>
            <span className={section2Styles.title_span0}>ПБ </span>
            <span className={section2Styles.title_span1}>Деликатес Систем-Проект</span>
            <span className={section2Styles.title_span2}>
              {' '}
              проделана сложная и интересная работа. Площадка изначально была размещена в непрофильном здании.
              <br />
              <br />
              Планировочные решения, потоки сырья, готовой продукции, отходов, персонала, «грязное» и «чистое»
              зонирование не позволяло компании пройти аудиты федеральных торговых сетей.
              <br />
              <br />
              Нестандартное решение – наружный «чистый» грузовой лифт, решило проблемы поточности и исключило все
              пересечения.
              <br />
              <br />
              Компания
            </span>
            <span className={section2Styles.title_span3}> </span>
            <span className={section2Styles.title_span4}><a style={{textDecoration: "none"}} href="https://mpmyastorg.ru/">МПК ООО «Мясторг»</a></span>
            <span className={section2Styles.title_span5}>
              {' '}
              успешно работает, занимая одно из лидирующих мест по поставкам в СПб шпика свиного, продуктов из шпика и
              деликатесов из свинины.
            </span>
          </span>
        </h1>
        <h1 className={section2Styles.hero_title}>МПК ООО «Мясторг»</h1>
      </div>
    </section>
  );
}

export default renderSection2;
