import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import renderSection2 from './section2';
import renderSection3 from './section3';
import renderSection8 from '../Projection/section8';

import styles from './index.module.scss';

function MPKOOO(props) {
    document.title = "МПК ООО «Мясторг»";

    return (
    <main className={cn(styles.main, props.className, 'mpkooo')} style={{overflow:"hidden"}}>
      <img className={styles.cover} src={'/assets/070c45d5c5149674522c2aa1448ba5be.png'} alt="alt text" />

      <div className={styles.flex_col}>
          {renderSection2(props)}
          {renderSection3(props)}
          {renderSection8(props)}
      </div>
    </main>
  );
}

MPKOOO.propTypes = {
  className: PropTypes.string
};

export default MPKOOO;
