import React from 'react';

import section6Styles from './section6.module.scss';

function renderSection6(props) {
  return (
    <section className={section6Styles.section6} data-aos="fade-right">
      <div className={section6Styles.section61}>
        <h1 className={section6Styles.title_box}>
          <span className={section6Styles.title}>
            <span className={section6Styles.title_span0}>
              При разработке раздела «ТХ» мы учитываем свой производственный опыт, погружаясь в процесс изнутри,
              рассматривая спроектированные технологические, производственные, логистические процессы с точки зрения  
              <br />
              тех, кто будет ежедневно участвовать в этих процессах (ИТР, мастера, бригадиры, рабочие), т. к. мы сами
              много лет отдали работе на производстве на различных мясо и птицеперерабатывающих предприятиях в различных
              регионах РФ и за рубежом.
              <br />
              <br />
              Отдельной строкой идет{' '}
            </span>
            <span className={section6Styles.title_span1}>проектирование АБК </span>
            <span
              className={
                section6Styles.title_span2
              }>{`(административно-бытового корпуса). Для сертификации площадки по FSSC-22000 (симбиоз GFSI и ISO), требуется не только идеальное зонирование производственных цехов, но и помещений АБК с четким и последовательным разделением раздевалок на "грязную" и "чистую" зоны, зон отдыха, приема пищи, умывальников, душевых, туалетов, помещений сушки обуви, прачечных и т.д.`}</span>
          </span>
        </h1>
      </div>
    </section>
  );
}

export default renderSection6;
