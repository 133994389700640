import React from 'react';

import section2Styles from './section2.module.scss';

function renderSection2(props) {
  return (
    <section className={section2Styles.section2} data-aos="fade-right">
      <div className={section2Styles.content_box}>
        <h1 className={section2Styles.title4}>
          Некоторые участки, цеха, отделения требуют 3D моделирования с последующей визуализацией.
          <br />
          Заказчик может наглядно увидеть результат того или иного планировочного решения, оценить эргономику,
          конфигурацию и технологичность производственного или логистического процесса.
          <br />
          Мы имеем собственные 3D библиотеки основного и вспомогательного оборудования, внутрицехового транспорта,
          инвентаря, по всем процессам первичной и глубокой переработки мяса. На некоторых моделях уровень детализации
          от 300 и более LOD G (уровень геометрической проработки).
        </h1>
        <h1 className={section2Styles.hero_title2}>Моделирование</h1>
      </div>
    </section>
  );
}

export default renderSection2;
