import React from 'react';

import section3Styles from './section3.module.scss';

function renderSection3(props) {
  return (
    <section className={section3Styles.section3}>
      <div className={section3Styles.flex_col}>
        <div className={section3Styles.flex_row} data-aos="fade-left">
          <div className={section3Styles.flex_col1}>
            <img
              className={section3Styles.image7}
              src={'/assets/60aa82742971f325c7a3a2ba5ab7cbd8.png'}
              alt="alt text"
            />
            <h1 className={section3Styles.title1_box}>
              <span className={section3Styles.title1}>
                <span className={section3Styles.title1_span0}>
                  Колбасное отделение цеха КФХ «Русь»
                  <br />
                </span>
                <span className={section3Styles.title1_span1}>Кировский р-н, Ленинградская обл. </span>
              </span>
            </h1>
          </div>

          <div className={section3Styles.flex_col2}>
            <img
              className={section3Styles.image8}
              src={'/assets/f8f8dc7e9ffa072b3d03e24eed94c0f9.png'}
              alt="alt text"
            />
            <h1 className={section3Styles.title1_box}>
              <span className={section3Styles.title1}>
                <span className={section3Styles.title1_span0}>
                  Отделение термообработки цеха КФХ «Русь»
                  <br />
                </span>
                <span className={section3Styles.title1_span11}>Кировский р-н, Ленинградская обл.</span>
              </span>
            </h1>
          </div>
        </div>

        <div className={section3Styles.flex_col3} data-aos="fade-right">
          <img className={section3Styles.image9} src={'/assets/2de2e8ebf8e51bb269518c8ee598741e.png'} alt="alt text" />
          <h1 className={section3Styles.title1_box}>
            <span className={section3Styles.title1}>
              <span className={section3Styles.title1_span0}>
                Отделение упаковки п/ф цеха КФХ «Русь»
                <br />
              </span>
              <span className={section3Styles.title1_span11}>Кировский р-н, Ленинградская обл.</span>
            </span>
          </h1>
        </div>

        <div className={section3Styles.flex_row1} data-aos="fade-left">
          <div className={section3Styles.flex_col4}>
            <img
              className={section3Styles.image10}
              src={'/assets/064c013ce668bb5febbc5a602ace82b7.png'}
              alt="alt text"
            />
            <h1 className={section3Styles.title2_box}>
              <span className={section3Styles.title2}>
                <span className={section3Styles.title2_span0}>
                  Вид на цех из кабинета директора <br />
                  «Экоферма Семейная»
                  <br />
                </span>
                <span className={section3Styles.title2_span1}>Гатчинский р-н, Ленинградская обл.</span>
              </span>
            </h1>
          </div>

          <div className={section3Styles.flex_col5}>
            <img
              className={section3Styles.image91}
              src={'/assets/b6e01f79299ae1c8db8ed7cf740c0828.png'}
              alt="alt text"
            />
            <h1 className={section3Styles.title1_box}>
              <span className={section3Styles.title1}>
                <span className={section3Styles.title1_span0}>
                  Колбасное отделение цеха «Экоферма Семейная»
                  <br />
                </span>
                <span className={section3Styles.title1_span11}>Гатчинский р-н, Ленинградская обл.</span>
                <span className={section3Styles.title1_span2}> </span>
              </span>
            </h1>
          </div>
        </div>

        <div className={section3Styles.flex_col6} data-aos="fade-right">
          <div className={section3Styles.flex_row2}>
            <img
              className={section3Styles.image11}
              src={'/assets/383027c90647c8fac19e08aabae87c88.png'}
              alt="alt text"
            />
            <img
              className={section3Styles.image6}
              src={'/assets/f51bb99e72e5ce38b92605d00cc7d438.png'}
              alt="alt text"
            />
          </div>

          <h1 className={section3Styles.title2_box}>
            <span className={section3Styles.title2}>
              <span className={section3Styles.title2_span0}>
                Убой свиней мощностью 80 голов/час <br />
              </span>
              <span className={section3Styles.title2_span1}>Краснодарский Край</span>
            </span>
          </h1>
        </div>

        <div className={section3Styles.flex_row3} data-aos="fade-left">
          <div className={section3Styles.flex_col7}>
            <img
              className={section3Styles.image61}
              src={'/assets/8145382ee33e9baee88e43bccaf202ba.png'}
              alt="alt text"
            />
            <h1 className={section3Styles.title1_box}>
              <span className={section3Styles.title1}>
                <span className={section3Styles.title1_span0}>
                  Отделение маринации «Агро-Плюс»
                  <br />
                </span>
                <span className={section3Styles.title1_span1}>г. Изобильный, Ставропольский край  </span>
              </span>
            </h1>
          </div>

          <div className={section3Styles.flex_col8}>
            <img
              className={section3Styles.image61}
              src={'/assets/5d7bde9639a13773deaa87e3d460e4ef.png'}
              alt="alt text"
            />
            <h1 className={section3Styles.title1_box}>
              <span className={section3Styles.title1}>
                <span className={section3Styles.title1_span0}>
                  Камера посола и созревания «Агро-Плюс»
                  <br />
                </span>
                <span className={section3Styles.title1_span1}>г. Изобильный, Ставропольский край  </span>
              </span>
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
}

export default renderSection3;
