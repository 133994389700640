import React, {useState} from 'react';

import section1Styles from './header.module.scss';
import Headroom from "react-headroom";
import { Twirl as Hamburger } from 'hamburger-react'
import {slideInRight} from "react-animations";
import styled, {keyframes} from "styled-components";

function Header(props) {

    const [isOpen, setOpen] = useState(false);
    const bounceAnimation = keyframes`${slideInRight}`;
    const BouncyDiv = styled.div`
      background-color: #ffffff;
      box-shadow: 0 0 22px 5px rgb(0 0 0 / 25%);
      animation: 1s ${bounceAnimation};
      height: 100%;
      width: 100%;
      border-radius: 0 0 0 30px;
  `;

    const [isHoveringMain, setIsHoveringMain] = useState(false);
    const [isHoveringServ, setIsHoveringServ] = useState(false);
    const [isHoveringAbout, setIsHoveringAbout] = useState(false);
    const [isHoveringRealized, setIsHoveringRealized] = useState(false);

    const handleMouseEnterMain = () => {
        setIsHoveringMain(true);
    };
    const handleMouseEnterServ = () => {
        setIsHoveringServ(true);
    };
    const handleMouseEnterAbout = () => {
        setIsHoveringAbout(true);
    };
    const handleMouseEnterRealized = () => {
        setIsHoveringRealized(true);
    };

    const handleMouseLeaveMain = () => {
        setIsHoveringMain(false);
    };
    const handleMouseLeaveServ = () => {
        setIsHoveringServ(false);
    };
    const handleMouseLeaveAbout = () => {
        setIsHoveringAbout(false);
    };
    const handleMouseLeaveRealized = () => {
        setIsHoveringRealized(false);
    };

    return (
        <section className={section1Styles.section1} id={'1'}>
            <Headroom style={{zIndex: 2025}}>
                <div className={section1Styles.section11}>
                    <div className={section1Styles.flex_col}>
                        <div className={section1Styles.flex_row}>
                            <a href="/">
                                <img
                                    className={section1Styles.image12}
                                    src={'/assets/10b14d6734f2f101c929ad202699fe90.png'}
                                    alt="alt text"/>
                            </a>

                            <div className={section1Styles.flex_col1}>
                                <h1 className={section1Styles.title3_box}>
                <span className={section1Styles.title3}>
                  <span className={section1Styles.title3_span0}>ПРОЕКТНОЕ БЮРО</span>
                  <span className={section1Styles.title3_span1}>
                    {' '}
                      <br className={section1Styles.br1} />
                    ДЕЛИКАТЕС СИСТЕМ-ПРОЕКТ
                  </span>
                </span>
                                </h1>
                                <h1 className={section1Styles.title}>Разработка проектов пищевой промышленности</h1>
                            </div>

                            <div className={section1Styles.flex_col6}>
                                <a href='/Main#footer' className={section1Styles.a_col}>
                                    <img className={section1Styles.image9}
                                         src={'/assets/79017580f9f34457eed8f4f58e66beee.png'}
                                         alt="alt text"
                                    />
                                </a>

                                <div className={section1Styles.hamburger}>
                                    <Hamburger direction={"left"} toggled={isOpen} toggle={setOpen}/>
                                </div>
                            </div>
                        </div>

                        <div className={section1Styles.flex_row1} id={"1222"}>
                            <div className={section1Styles.flex_col2} onMouseEnter={handleMouseEnterMain} onMouseLeave={handleMouseLeaveMain}>
                                <div
                                    className={section1Styles.rect13}
                                    style={{backgroundColor: isHoveringMain ? "#b2b1b1" : "#F1F1F1FF"}}
                                />

                                <a href="/Main#1" style={{textDecoration: "none"}}><h1
                                    className={section1Styles.title4}
                                    style={{fontWeight: isHoveringMain ? 700 : 600}}>ГЛАВНАЯ</h1>
                                </a>
                            </div>

                            <div className={section1Styles.flex_col3} onMouseEnter={handleMouseEnterServ} onMouseLeave={handleMouseLeaveServ}>
                                <div
                                    className={section1Styles.rect12}
                                    style={{backgroundColor: isHoveringServ ? "#b2b1b1" : "#F1F1F1FF"}}
                                />
                                <a href="/Main#4" style={{textDecoration: "none"}}><h1
                                    className={section1Styles.title4}
                                    style={{fontWeight: isHoveringServ ? 700 : 600}}>УСЛУГИ</h1>
                                </a>
                            </div>

                            <div className={section1Styles.flex_col4} onMouseEnter={handleMouseEnterAbout} onMouseLeave={handleMouseLeaveAbout}>
                                <div
                                    className={section1Styles.rect11}
                                    style={{backgroundColor: isHoveringAbout ? "#b2b1b1" : "#F1F1F1FF"}}
                                />
                                <a href="/Main#7" style={{textDecoration: "none"}}><h1
                                    className={section1Styles.title4}
                                    style={{fontWeight: isHoveringAbout ? 700 : 600}}>О КОМПАНИИ</h1>
                                </a>
                            </div>

                            <div className={section1Styles.flex_col5} onMouseEnter={handleMouseEnterRealized} onMouseLeave={handleMouseLeaveRealized}>
                                <div
                                    className={section1Styles.rect10}
                                    style={{backgroundColor: isHoveringRealized ? "#b2b1b1" : "#F1F1F1FF"}}
                                />
                                <a href="/Main#6" style={{textDecoration: "none"}}><h1
                                    className={section1Styles.title5}
                                    style={{fontWeight: isHoveringRealized ? 700 : 600}}>РЕАЛИЗОВАННЫЕ ПРОЕКТЫ</h1>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Headroom>

            <div style={{display: isOpen ? "block": "none", zIndex: 2024}} className={section1Styles.hamburgerDiv}>

                <BouncyDiv>
                    <a href="/" style={{width: "100%"}}>
                        <img
                            className={section1Styles.image13}
                            src={'/assets/10b14d6734f2f101c929ad202699fe90.png'}
                            alt="alt text"/>
                    </a>

                    <div className={section1Styles.flex_row11}>
                        <div className={section1Styles.flex_col51}>
                            <a href="/Main#1" style={{textDecoration: "none"}}><h1 className={section1Styles.title41}>Главная</h1></a>
                        </div>

                        <div className={section1Styles.flex_col51}>
                            <div className={section1Styles.rect101} />
                            <a href="/Main#4" style={{textDecoration: "none"}}><h1 className={section1Styles.title41}>Услуги</h1></a>
                        </div>

                        <div className={section1Styles.flex_col51}>
                            <div className={section1Styles.rect101} />
                            <a href="/Main#7" style={{textDecoration: "none"}}><h1 className={section1Styles.title41}>О компании</h1></a>
                        </div>

                        <div className={section1Styles.flex_col51}>
                            <div className={section1Styles.rect101} />
                            <a href="/Main#6" style={{textDecoration: "none"}}><h1 className={section1Styles.title41}>Реализованые проекты</h1></a>
                        </div>
                    </div>

                </BouncyDiv>

            </div>

        </section>
    );
}

export default Header;
