import React from 'react';

import section2Styles from './section2.module.scss';

function renderSection2(props) {
  return (
    <section className={section2Styles.section2} data-aos="fade-right">
      <div className={section2Styles.content_box}>
        <h1 className={section2Styles.title1}>
          На рынке существует множество консалтинговых компаний, предоставляющих услуги технологического аудита,
          результатом которого являются презентации на 100 - 200 страниц, с множеством графиков, диаграмм, рекомендаций
          и т.д. (за исключением аудитов от научных организаций, где предполагаются лабораторные исследования).
          <br />
          По итогам устраивается презентация для руководителей подразделений, на которых звучат банальные выводы и
          прописные истины, таким образом ожидания заказчика не оправдываются.
          <br />
          Между тем, применительно к условиям РФ существуют 3 (три) вида технологичного аудита потенциально интересных
          заказчику.
          <br />
          Информацию об этом вы можете получить после соответствующего запроса.
        </h1>
        <h1 className={section2Styles.hero_title1}>Аудит</h1>
      </div>
    </section>
  );
}

export default renderSection2;
