import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect} from 'react-router-dom';
import Projection from './components/Projection';
import PreProjection from './components/PreProjection';
import Modeling from './components/Modeling';
import Main from './components/Main';
import MPKOOO from './components/MPKOOO';
import KFHRus from './components/KFHRus';
import Farming from './components/Farming';
import Consulting from './components/Consulting';
import Balakovskii from './components/Balakovskii';
import Audit from './components/Audit';
import AOS from 'aos';
import { isMobile } from 'react-device-detect';
import HttpsRedirect from 'react-https-redirect';
import Header from "./components/Header/index";
import Footer from "./components/Footer/index";

import 'aos/dist/aos.css';
import './fonts.css';

function App() {
  useEffect(() => {
    setTimeout(() => {
      AOS.init({
        offset: isMobile ? 10 : 100,
      });
      AOS.refresh();
    }, 1500);
  }, []);

  return (<div>
        {Header()}
        <HttpsRedirect>
          <Router basename={process.env.BASE_PATH}>
            <Switch>
              <Route exact path="/">
                <div>
                  pxCode Screen List: <br />
                  <Link to="/Projection">Projection</Link>
                  <br />
                  <Link to="/PreProjection">PreProjection</Link>
                  <br />
                  <Link to="/Modeling">Modeling</Link>
                  <br />
                  <Link to="/Main">Main</Link>
                  <br />
                  <Link to="/MPKOOO">MPKOOO</Link>
                  <br />
                  <Link to="/KFHRus">KFHRus</Link>
                  <br />
                  <Link to="/Farming">Farming</Link>
                  <br />
                  <Link to="/Consulting">Consulting</Link>
                  <br />
                  <Link to="/Balakovskii">Balakovskii</Link>
                  <br />
                  <Link to="/Audit">Audit</Link>
                </div>
                <Redirect from='/'  to='/Main' />
              </Route>

              <Route exact path="/Projection" component={Projection} />
              <Route exact path="/PreProjection" component={PreProjection} />
              <Route exact path="/Modeling" component={Modeling} />
              <Route exact path="/Main" component={Main} />
              <Route exact path="/MPKOOO" component={MPKOOO} />
              <Route exact path="/KFHRus" component={KFHRus} />
              <Route exact path="/Farming" component={Farming} />
              <Route exact path="/Consulting" component={Consulting} />
              <Route exact path="/Balakovskii" component={Balakovskii} />
              <Route exact path="/Audit" component={Audit} />
            </Switch>
          </Router>
        </HttpsRedirect>
        {Footer()}
      </div>
  );
}

export default App;
