import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
// import { AwesomeButton } from "react-awesome-button";

import section8Styles from './section8.module.scss';
// import awsBtn from './section8.module.scss'

function renderSection8(props) {

  const form = new useRef();

  const [name,setName] = new useState('');
  const [theme,setTheme] = new useState('');
  const [email,setEmail] = new useState('');
  const [message,setMessage] = new useState('');

  const serviceID = 'service_xz8lhqn';
  const templateID = 'template_5aw4syh';
  const publicKey = 'C8Fzzvrrv0B35Zpn6';

  const sendEmail = (e) => {
    e.preventDefault();
    if (name && theme && email && message) {
      emailjs.sendForm(serviceID, templateID, form.current, {publicKey: publicKey,})
          .then((result) => {
            console.log(result.text);
            setName('');
            setEmail('');
            setTheme('');
            setMessage('');
            alert("Сообщение отправлено");
          }, (error) => {
            console.log(error.text);
            alert("Ошибка");
          });
    } else {alert("Все поля необходимо заполнить")}
  };

    return (
      <section className={section8Styles.section8} data-aos="fade-right">
        <form onSubmit={sendEmail} ref={form}>
        <div className={section8Styles.box}>
          <div className={section8Styles.flex_col}>
            <div className={section8Styles.flex_col1}>
              <div className={section8Styles.flex_col2}>
                <h1 className={section8Styles.title1}>Ваше имя</h1>

                <div className={section8Styles.rect4}>
                  <div className={section8Styles.rect41} />
                  <h1 className={section8Styles.text_name_box}>
                    <input
                        className={section8Styles.text_name}
                        type={'text'}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        name="name"
                    />
                  </h1>
                </div>
              </div>

              <div className={section8Styles.flex_col3}>
                <h1 className={section8Styles.title1}>Ваша электронная почта</h1>

                <div className={section8Styles.rect4}>
                  <div className={section8Styles.rect41} />
                  <h1 className={section8Styles.text_email_box}>
                    <input
                        className={section8Styles.text_email}
                        type={'text'}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                    />
                  </h1>
                </div>
              </div>

              <div className={section8Styles.flex_col3}>
                <h1 className={section8Styles.title1}>Тема сообщения</h1>

                <div className={section8Styles.rect4}>
                  <div className={section8Styles.rect41} />
                  <h1 className={section8Styles.text_email_theme_box}>
                    <input
                        className={section8Styles.text_email_theme}
                        type={'text'}
                        value={theme}
                        onChange={(e) => setTheme(e.target.value)}
                        name="theme"
                    />
                  </h1>
                </div>
              </div>

              <div className={section8Styles.flex_col4}>
                <h1 className={section8Styles.title11}>Сообщение</h1>

                <div className={section8Styles.rect5}>
                  <div className={section8Styles.rect51} />
                  <h1 className={section8Styles.text_message_box}>
                    <textarea
                        className={section8Styles.text_message}
                        name='message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                  </h1>
                </div>
              </div>
            </div>

            <button className={section8Styles.btn} type={"submit"}>
              {/* TODO */}
              Отправить
            </button>

            {/*<AwesomeButton cssModule={awsBtn} type="primary">Отправить</AwesomeButton>*/}
            {/*<AwesomeButton type="primary">Primary</AwesomeButton>*/}
          </div>

          <h1 className={section8Styles.hero_title1}>Форма для запроса</h1>
        </div>
        </form>
      </section>
    );
}

export default renderSection8;
