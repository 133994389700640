import React from 'react';

import section4Styles from './section4.module.scss';

function renderSection4(props) {
  return (
    <section className={section4Styles.section4} id={'4'} data-aos="fade-up">
      <div className={section4Styles.content_box4}>
        <div className={section4Styles.flex_row}>
          <div className={section4Styles.flex_col}>
            <div className={section4Styles.box15}>
              <div className={section4Styles.flex_col1}>
                <h1 className={section4Styles.big_title1}>ПРЕДПРОЕКТНАЯ СТАДИЯ</h1>

                <div className={section4Styles.box17}>
                  <h2 className={section4Styles.medium_title3}>● ТЭО (Технико-экономическое обоснование)</h2>
                  <h2 className={section4Styles.medium_title31}>● Оценка площадки для строительства</h2>
                  <h2 className={section4Styles.medium_title32}>● Разработка структуры ассортимента</h2>
                </div>

                <a href="/PreProjection">
                  <div className={section4Styles.box16}>
                    <h3 className={section4Styles.subtitle2}>Подробнее</h3>
                    <img
                      className={section4Styles.image6}
                      src={'/assets/b3c918171a62fd68a2c0b48e25f0237f.svg'}
                      alt="alt text"
                    />
                  </div>
                </a>
              </div>
            </div>

            <div className={section4Styles.flex_col2}>
              <h1 className={section4Styles.big_title11}>ПРОЕКТИРОВАНИЕ</h1>

              <div className={section4Styles.box14}>
                <div className={section4Styles.flex_col3}>
                  <h2 className={section4Styles.medium_title33}>● Разработка раздела “ТХ”</h2>
                  <h2 className={section4Styles.medium_title33}>● BIM - проектирование</h2>
                  <h2 className={section4Styles.medium_title34}>● Проектирование АБК</h2>
                </div>
              </div>

              <a href="/Projection" className={section4Styles.a1}>
                <div className={section4Styles.flex_row1}>
                  <h3 className={section4Styles.subtitle21}>Подробнее</h3>
                  <img
                    className={section4Styles.image5}
                    src={'/assets/1324df77e7eb64f91292e82ea815e0e7.svg'}
                    alt="alt text"
                  />
                </div>
              </a>
            </div>

            <div className={section4Styles.flex_col4}>
              <div className={section4Styles.box10}>
                <h1 className={section4Styles.big_title12}>МОДЕЛИРОВАНИЕ</h1>
              </div>

              <div className={section4Styles.box18}>
                <div className={section4Styles.flex_col5}>
                  <h2 className={section4Styles.medium_title35}>● 3D Моделирование с визуализацией</h2>
                  <h2 className={section4Styles.medium_title36}>● Собственные 3D библиотеки</h2>
                  <h2 className={section4Styles.medium_title35}>● Уровень детализации 300+ LOD G</h2>
                  <h2 className={section4Styles.medium_title37}>
                    ● Визуализация спроектированных
                    <br />
                    производстенных процессов (цехов, отделений, участков)
                  </h2>
                </div>
              </div>

              <a href="/Modeling" className={section4Styles.a1}>
                <div className={section4Styles.box7}>
                  <div className={section4Styles.box8}>
                    <h3 className={section4Styles.subtitle22}>Подробнее</h3>
                      <img
                          className={section4Styles.image51}
                          src={'/assets/1324df77e7eb64f91292e82ea815e0e7.svg'}
                          alt="alt text"
                      />
                  </div>
                </div>
              </a>
            </div>

            <div className={section4Styles.flex_col4}>
              <div className={section4Styles.box11}>
                <h1 className={section4Styles.big_title13}>КОНСУЛЬТИРОВАНИЕ</h1>
              </div>

              <div className={section4Styles.box9}>
                <h2 className={section4Styles.medium_title2}>● Услуги профессионального технолога</h2>
                <h2 className={section4Styles.medium_title2}>● Работа по договору сопровождения проекта</h2>
                <h2 className={section4Styles.medium_title21}>● Разработка НТД</h2>
              </div>

              <a href="/Consulting">
                <div className={section4Styles.box71}>
                  <div className={section4Styles.box8}>
                    <h3 className={section4Styles.subtitle22}>Подробнее</h3>
                    <img
                      className={section4Styles.image51}
                      src={'/assets/1324df77e7eb64f91292e82ea815e0e7.svg'}
                      alt="alt text"
                    />
                  </div>
                </div>
              </a>
            </div>

            <div className={section4Styles.flex_col4}>
              <h1 className={section4Styles.big_title14}>АУДИТ</h1>
              <h2 className={section4Styles.medium_title22}>● Технологический аудит производства</h2>

              <a href="/Audit" className={section4Styles.a1}>
                <div className={section4Styles.box72}>
                  <div className={section4Styles.box8}>
                    <h3 className={section4Styles.subtitle22}>Подробнее</h3>
                    <img
                      className={section4Styles.image51}
                      src={'/assets/1324df77e7eb64f91292e82ea815e0e7.svg'}
                      alt="alt text"
                    />
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div className={section4Styles.flex_col6} >
            <img
              className={section4Styles.image8}
              src={'/assets/4a20e3274075be21fad6edeb6dcd74eb.png'}
              alt="alt text"
            />
            <img
              className={section4Styles.image81}
              src={'/assets/bb6ed63a56b72d3b9be7efdff64ece15.png'}
              alt="alt text"
            />
          </div >
        </div>

        <h1 className={section4Styles.hero_title1}>УСЛУГИ</h1>
      </div>

      <div className={section4Styles.placeholder1} />
    </section>
  );
}

export default renderSection4;
