import React from 'react';

import section5Styles from './section5.module.scss';

function renderSection5(props) {
  return (
    <section className={section5Styles.section5} data-aos="fade-left">
      <img className={section5Styles.image} src={'/assets/b4fa150baae6555167dadaafa3a6fe97.png'} alt="alt text" />
    </section>
  );
}

export default renderSection5;
