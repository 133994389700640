import React from 'react';

import section2Styles from './section2.module.scss';

function renderSection2(props) {
  return (
    <section className={section2Styles.section2} data-aos="fade-right">
      <div className={section2Styles.content_box}>
        <h1 className={section2Styles.title_box}>
          <span className={section2Styles.title}>
            <span className={section2Styles.title_span0}>
              ТЭО (Технико-экономическое обоснование)
              <br />
            </span>
            <span className={section2Styles.title_span1}>
              В том числе предварительная оценка капиталовложений в частности и целесообразность реализации проекта в
              целом.
              <br />
              <br />
            </span>
            <span className={section2Styles.title_span2}>
              Оценка площадки для строительства
              <br />
            </span>
            <span className={section2Styles.title_span3}>
              Мы проводим оценку участка под застройку или, если здание существует, проводим экспресс-осмотр
              существующих инженерных сетей, очистных сооружений, систем водоотведения, вентиляции. Проводим оцифровку в
              DWG. По итогам выезда на объект выдаем рекомендации о целесообразности размещения производственного
              корпуса на конкретном земельном участке.
            </span>
          </span>
        </h1>
        <h1 className={section2Styles.hero_title1}>Предпроектная стадия</h1>
      </div>
    </section>
  );
}

export default renderSection2;
