import React from 'react';

import section8Styles from './footer.module.scss';

function Footer(props) {
    return (
        <section className={section8Styles.section8} id={'footer'}>
            <div className={section8Styles.content_box4}>
                <div className={section8Styles.flex_col}>
                    <h3 className={section8Styles.subtitle}>Быстрая связь:</h3>

                    <div className={section8Styles.flex_row}>
                        <img className={section8Styles.image} src={'/assets/290204e24179c8869088b98c5cb622cc.svg'} alt="alt text" />
                        <h3 className={section8Styles.subtitle1}><a href="tel:+79213000484">+ 7 (921) 300-04-84</a></h3>
                    </div>

                    <div className={section8Styles.flex_row1}>
                        <img
                            className={section8Styles.image1}
                            src={'/assets/b15bac6a6cb0048dbc2a0e34144b2297.svg'}
                            alt="alt text"
                        />
                        <h3 className={section8Styles.subtitle11}><a href="mailto:igorcher100@gmail.com">igorcher100@gmail.com</a></h3>
                    </div>

                    <div className={section8Styles.flex_row2}>
                        <h1 className={section8Styles.hero_title}>©</h1>
                        <h2 className={section8Styles.medium_title}>Проектное бюро</h2>
                        <h2 className={section8Styles.medium_title1}>Деликатес Систем-Проект</h2>
                        <h2 className={section8Styles.medium_title2}>, 2024</h2>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Footer;
