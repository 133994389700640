import React from 'react';

import section6Styles from './section6.module.scss';

function renderSection6(props) {
  return (
    <section className={section6Styles.section6} data-aos="fade-up" id={'6'}>
      <div className={section6Styles.content_box2}>
        <div className={section6Styles.flex_col}>
          <div className={section6Styles.flex_row}>
            <img
              className={section6Styles.image2}
              src={'/assets/885535becdd097d283f93079669c7132.png'}
              alt="alt text"
            />

            <div className={section6Styles.flex_col1}>
              <div className={section6Styles.box5}>
                <h1 className={section6Styles.big_title}>КФХ “Русь”</h1>
                <h1 className={section6Styles.title}>
                  Разработка проекта реконструкции и запуск цеха по производству колбас, деликатесов в КФХ «Русь».
                </h1>
              </div>

              <div className={section6Styles.box4}>
                <div className={section6Styles.flex_row1}>
                  <img
                    className={section6Styles.image3}
                    src={'/assets/910050360696b912a06d84a342e7067d.svg'}
                    alt="alt text"
                  />
                  <h1 className={section6Styles.title2}>Кировский р-н Ленинградская область</h1>
                </div>
              </div>

              <button className={section6Styles.btn} onClick={() => window.location = '/KFHRus'}>
                {/* TODO */}
                Подробнее
              </button>
            </div>
          </div>

          <div className={section6Styles.flex_row2}>
            <img
              className={section6Styles.image21}
              src={'/assets/d4913aa2481d7a0b84b36270ed128e96.png'}
              alt="alt text"
            />

            <div className={section6Styles.flex_col2}>
              <div className={section6Styles.flex_col3}>
                <h1 className={section6Styles.big_title}>МПК ООО “Мясторг”</h1>
                <h1 className={section6Styles.title1}>
                  Разработка проекта реконструкции мясоперерабатывающего предприятия.
                </h1>
              </div>

              <div className={section6Styles.box3}>
                <div className={section6Styles.flex_row3}>
                  <img
                    className={section6Styles.image3}
                    src={'/assets/910050360696b912a06d84a342e7067d.svg'}
                    alt="alt text"
                  />
                  <h1 className={section6Styles.title21}>
                    г. Гулькевичи
                    <br />
                    Краснодарский край
                  </h1>
                </div>
              </div>

              <button className={section6Styles.btn1} onClick={() => window.location = '/MPKOOO'}>
                {/* TODO */}
                Подробнее
              </button>
            </div>
          </div>

          <div className={section6Styles.flex_row4}>
            <img className={section6Styles.rect5} src={'/assets/096b7f95656807835135f4a1a6f79faf.png'} alt="alt text" />

            <div className={section6Styles.flex_col1}>
              <div className={section6Styles.box2}>
                <div className={section6Styles.flex_col4}>
                  <h1 className={section6Styles.big_title}>Балаковский мясокомбинат</h1>
                  <h1 className={section6Styles.title3}>Разработка проекта полной реконструкции мясокомбината.</h1>
                </div>
              </div>

              <div className={section6Styles.box1}>
                <img
                  className={section6Styles.image31}
                  src={'/assets/910050360696b912a06d84a342e7067d.svg'}
                  alt="alt text"
                />
                <h1 className={section6Styles.title22}>
                  г. Балаково
                  <br />
                  Саратовская область
                </h1>
              </div>

              <button className={section6Styles.btn1} onClick={() => window.location = '/Balakovskii'}>
                {/* TODO */}
                Подробнее
              </button>
            </div>
          </div>
        </div>

        <h1 className={section6Styles.hero_title1}>РЕАЛИЗОВАННЫЕ ПРОЕКТЫ</h1>
      </div>
    </section>
  );
}

export default renderSection6;
