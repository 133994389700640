import React from 'react';

import section4Styles from './section4.module.scss';

function renderSection4(props) {
  return (
    <section className={section4Styles.section4}>
      <div className={section4Styles.flex_row}>
        <div className={section4Styles.flex_col}>
          <img
            className={section4Styles.image4}
            src={'/assets/f84ddb93b62f4aecf61a864f153c23bc.png'}
            alt="alt text"
            data-aos="fade-right"
          />
          <img
            className={section4Styles.image41}
            src={'/assets/cdf377d6a57a2947dc372f06d1f54c98.png'}
            alt="alt text"
            data-aos="fade-left"
          />
          <img
            className={section4Styles.image6}
            src={'/assets/79f7906db6e080643d0aec54b1c8be9d.png'}
            alt="alt text"
            data-aos="fade-right"
          />
        </div>

        <div className={section4Styles.flex_col1}>
          <img
            className={section4Styles.image5}
            src={'/assets/4fd0012f1c8415fb1c55601dc79eb626.png'}
            alt="alt text"
            data-aos="fade-left"
          />
          <img
            className={section4Styles.image7}
            src={'/assets/b81ae1a7ea5102da21b0bff3f6d2ad13.png'}
            alt="alt text"
            data-aos="fade-right"
          />
        </div>
      </div>
    </section>
  );
}

export default renderSection4;
