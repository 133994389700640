import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import renderSection2 from './section2';
import renderSection3 from './section3';
import renderSection8 from '../Projection/section8';

import styles from './index.module.scss';

function Audit(props) {
    document.title = "Аудит";

    return (
    <main className={cn(styles.main, props.className, 'audit')} style={{overflow:"hidden"}}>
      <img className={styles.cover} src={'/assets/be1fd6ca722962b4d1663286d0485132.png'} alt="alt text" />

      <div className={styles.flex_col}>
          {renderSection2(props)}
          {renderSection3(props)}
          {renderSection8(props)}
      </div>
    </main>
  );
}

Audit.propTypes = {
  className: PropTypes.string
};

export default Audit;
