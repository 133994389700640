import React from 'react';

import section3Styles from './section3.module.scss';

function renderSection3(props) {
  return (
    <section className={section3Styles.section3} data-aos="fade-left">
      <img className={section3Styles.image3} src={'/assets/99e3d65287a1db6e48abcaa16eec74bd.png'} alt="alt text" />
    </section>
  );
}

export default renderSection3;
