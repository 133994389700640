import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import renderSection3 from './section3';
import renderSection4 from './section4';
import renderSection5 from './section5';
import renderSection6 from './section6';
import renderSection7 from './section7';

import styles from './index.module.scss';

function Main(props) {
    document.title = "ПБ Деликатес Систем-Проект. Разработка проектов пищевой промышленности";

    return (
    <main className={cn(styles.main, props.className, 'main')} style={{overflow:"hidden"}}>
      <img className={styles.cover} src={'/assets/745855ca7d4bce2a00ba39f237a7bdcc.png'} alt="alt text" />

      <div className={styles.flex_col}>
        <img className={styles.section2} src={'/assets/c3d15b07f89d775d8ad9d050b50badc8.jpg'} alt="alt text"/>
        {renderSection3(props)}
        {renderSection4(props)}
        {renderSection5(props)}
        {renderSection6(props)}
        {renderSection7(props)}
      </div>
    </main>
  );
}

Main.propTypes = {
  className: PropTypes.string,
  // title4: PropTypes.string.isRequired
};

export default Main;
