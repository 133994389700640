import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import renderSection2 from './section2';
import renderSection8 from '../Projection/section8';

import styles from './index.module.scss';

function Balakovskii(props) {
    document.title = "Балаковский Мясокомбинат";

    return (
    <main className={cn(styles.main, props.className, 'balakovskii')} style={{overflow:"hidden"}}>
      <img className={styles.cover} src={'/assets/ea83c4f6afb63d7a8664de5b91a43079.png'} alt="alt text" />

      <div className={styles.flex_col}>
          {renderSection2(props)}
          {renderSection8(props)}
      </div>
    </main>
  );
}

Balakovskii.propTypes = {
  className: PropTypes.string
};

export default Balakovskii;
