import React from 'react';

import section4Styles from './section4.module.scss';

function renderSection4(props) {
  return (
    <section className={section4Styles.section4} data-aos="fade-right">
      <div className={section4Styles.section41}>
        <h1 className={section4Styles.title_box}>
          <span className={section4Styles.title}>
            <span className={section4Styles.title_span0}>
              Однако, если заказчик четко поставил задачу, имеет последовательную дорожную карту достижения поставленной
              задачи, мы становимся одной командой и выдаем отличный результат.
              <br />
              <br />В некоторых случаях мы выполняем графическую часть проекта в 3D с использованием принципов
              Bim-проектирования. <br />
            </span>
            <span className={section4Styles.title_span1}>BIM (Building Information Modeling)</span>
            <span className={section4Styles.title_span2}>
              {' '}
              - технология информационного моделирования в строительстве. Основана на принципах информационных 3D
              моделей оборудования, инженерных систем и коммуникаций в виде атрибутов, спецификаций.
            </span>
          </span>
        </h1>
      </div>
    </section>
  );
}

export default renderSection4;
