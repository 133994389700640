import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import renderSection2 from './section2';
import renderSection3 from './section3';
import renderSection4 from './section4';
import renderSection5 from './section5';
import renderSection6 from './section6';
import renderSection7 from './section7';
import renderSection8 from './section8';

import styles from './index.module.scss';

function Projection(props) {
    document.title = "Проектирование";

    return (
    <div className={cn(styles.group, props.className, 'projection')} style={{overflow:"hidden"}}>
      <div className={styles.group1}>
        <img className={styles.cover} src={'/assets/fba077dec063e8bda9bfe523c888400c.png'} alt="alt text" />

        <main className={styles.main}>
          {renderSection2(props)}
          {renderSection3(props)}
          {renderSection4(props)}
          {renderSection5(props)}
          {renderSection6(props)}
          {renderSection7(props)}
          {renderSection8(props)}
        </main>
      </div>
    </div>
  );
}

Projection.propTypes = {
  className: PropTypes.string
};

export default Projection;
