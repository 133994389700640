import React from 'react';

import section2Styles from './section2.module.scss';

function renderSection2(props) {
  return (
    <section className={section2Styles.section2} data-aos="fade-right">
      <div className={section2Styles.content_box}>
        <h1 className={section2Styles.title_box}>
          <span className={section2Styles.title}>
            <span className={section2Styles.title_span0}>
              {`Мы специализируемся на разработке раздела  «ТХ» (технология), ставшего с 01.09.2022 отдельным разделом проектной документации "Раздел 6", как это и было в советский период.`}
              <br />
              <br />
              Это самый важный раздел проекта, от качества разработки которого зависит результат воплощения бизнес-идеи:
              <br />
              структура ассортимента, сумма инвестиций, архитектурно-планировочные решения.{' '}
            </span>
            <span className={section2Styles.title_span1}>
              Цена ошибки - огромные финансовые потери и, как следствие, потеря бизнеса
            </span>
            <span className={section2Styles.title_span2}>
              .<br />
              <br />
              {`Раздел ТХ - это огромный пласт информации, где под термин "технология" подведено большое кол-во подразделов, одним словом, что необходимо, в каком виде и количестве`}
              <br />
              для выработки продукции согласно утвержденной структуре ассортимента. Сколько и каких потребуется
              энергоносителей, кол-во персонала по штатному расписанию и мн. др.
              <br />
              Мы индивидуально подходим к каждому заказчику, и не редки случаи, когда мы рекомендуем не инвестировать в
              новое строительство или реконструкцию.
            </span>
          </span>
        </h1>
        <h1 className={section2Styles.hero_title}>Проектирование</h1>
      </div>
    </section>
  );
}

export default renderSection2;
