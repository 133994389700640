import React from 'react';

import section2Styles from './section2.module.scss';

function renderSection2(props) {
  return (
    <section className={section2Styles.section2} data-aos="fade-right">
      <div className={section2Styles.content_box}>
        <h1 className={section2Styles.title1_box}>
          <span className={section2Styles.title1}>
            <span className={section2Styles.title1_span0}>
              Крестьянское фермерское хозяйство специализируется на выращивании индейки.
              <br />
              Долгое время собственник реализовывал свою продукцию в виде тушки потрошеной и частей тушки после
              разделки. Постепенно фермер прошел к осознанию необходимости глубокой переработки своей птицы, которая
              значительно бы увеличила прибыль хозяйства и позволила бы более рационально и технологично срабатывать
              обрезь и ВСР (вторичные сырьевые ресурсы).
              <br />
              <br />
              Обратившись к нам, глава хозяйства получил весь комплекс{' '}
            </span>
            <span className={section2Styles.title1_span1}><a style={{textDecoration: "none"}} href="/Main#4">услуг</a></span>
            <span className={section2Styles.title1_span2}>
              , проект реконструкции, поиск и закупку оборудования, запуск цеха. Одновременно специалисты компании ПБ{' '}
            </span>
            <span className={section2Styles.title1_span3}>Деликатес Систем-Проект</span>
            <span className={section2Styles.title1_span4}>
              {' '}
              обучили в виде мастер-классов весь персонал, задействованный в цеху. Кроме того, нами были разработаны все
              линейки продукции, все нормативная документация, а также проведено декларирование.
              <br />
              <br />
              Отдельно хотелось бы отметить защиту нашими технологами-проектировщиками проектных решений и экспертизы
              проектной документации в ГБУ ЛО «СББЖ Кировского и Тосненского районов». Причем без присутствия на
              экспертном комитете собственника фермерского хозяйства.
              <br />
              <br />
              На данный момент{' '}
            </span>
            <span className={section2Styles.title1_span5}><a style={{textDecoration: "none"}} href="https://fermaspb.ru/fermers/kfh-rus">КФХ «Русь»</a></span>
            <span className={section2Styles.title1_span6}>
              {' '}
              является редким примером хозяйства (возможно единственным) в Ленинградской области, имеющим свой
              полноценный промышленный перерабатывающий цех.
              <br />
              <br />
              Колбасы и деликатесы успешно заведены в такие ТС как «Перекресток» и «Вкусвилл».
            </span>
          </span>
        </h1>
        <h1 className={section2Styles.hero_title1}>КФХ «Русь»</h1>
      </div>
    </section>
  );
}

export default renderSection2;
