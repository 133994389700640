import React from 'react';

import section4Styles from './section4.module.scss';

function renderSection4(props) {
  return (
    <section className={section4Styles.section4} data-aos="fade-right">
      <div className={section4Styles.section41}>
        <h1 className={section4Styles.title_box}>
          <span className={section4Styles.title}>
            <span className={section4Styles.title_span0}>
              Разработка структуры ассортимента
              <br />
            </span>
            <span className={section4Styles.title_span1}>
              Основанием для корректного системного проектирования является принятый и утверждённый заказчиком
              главный документ - структура ассортимента. Это можно сравнить с конструктором, имеющим множество
              <br />
              деталей, который очень трудно, а зачастую просто невозможно собрать без инструкции.
              <br />
              Структура ассортимента является подробной инструкцией и основанием для разработки проекта. Как показывает
              наш опыт, заказчики с небольшими объёмами и фермеры зачастую затрудняются принять правильную для своих
              задач, сырьевой составляющей и своего региона структуру ассортимента.
              <br />В этих случаях мы как технологи-производственники, работавшие на различных перерабатывающих
              площадках, всегда приходим на помощь и совместными усилиями достигаем оптимального результата, являющимся
              <br />
              индивидуальным для каждого конкретного заказчика.
            </span>
          </span>
        </h1>
      </div>
    </section>
  );
}

export default renderSection4;
